import React from "react";

export default function Info() {
  return (
    <div className="info">
      <div className="info-wrapper">
        <h1>The Best Way to Book Bus Ticket</h1>
        <p>
          Do you want to know the best way to book tickets online securely and
          how to book tickets in advance? SavariBus.com is the best online
          platform for bus booking, You can check the amenities provided,
          compare booking fares, check bus timings in the searched route, other
          facilities and more with us. You can find an affordable and
          cost-effective online travel bookings facility at SavariBus.
        </p>
        <p>
          With SavariBus, online bus ticket booking is now very easy. You simply
          need to fill in the details of your journey in our search bar
          (departure city, destination city and the date of journey) click on
          the search button to get all the bus operators available for booking
          tickets online. Then, select the bus that best suits your travel needs
          and securely complete your booking online on SavariBus.com.
        </p>

        <h1>Benefits of Booking Bus Tickets Online</h1>
        <p>
          In this day and age of technology, offline modes of bus ticket booking
          are no more a preference. Online ticket booking is easy, fast and
          hassle-free. SavariBus ticks off all three with our easy app and
          website navigation.
        </p>
        <p>
          Booking bus tickets online with SavariBus has solved many problems
          that people face while booking their tickets at offline counters or
          travel agents. Download the SavariBus App to see the benefits of
          making a bus ticket booking online.
        </p>

        <p>
          Here's listing all the benefits of booking bus tickets online on
          SavariBus
        </p>
        <ul>
          <li>Avoid standing in long queues at offline bus ticket counters.</li>
          <li>No more hassle of approaching travel agents.</li>
          <li>Choose from multiple bus services.</li>
          <li>Book both Private and SRTC bus tickets online.</li>
          <li>Check bus ticket availability online.</li>
          <li>
            Get bus timings, ticket price, boarding & dropping point details
            online.
          </li>
          <li>Access to payment partner discounts and cashback offers.</li>
          <li>Free cancellation feature.</li>
          <li>24/7 customer support.</li>
          <li>Each and every transaction is simple, safe and secure.</li>
        </ul>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { data } from "../location";
import * as TbIcons from "react-icons/tb";
import * as CgIcons from "react-icons/cg";
import * as HiIcons from "react-icons/hi";
import * as FaIcons from "react-icons/fa";

export const Form = ({ handleAddForm }) => {
  const [filteredSources, setFilteredSources] = useState([]);
  const [filteredDestination, setFilteredDestination] = useState([]);

  const [date, setDate] = React.useState(new Date());

  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");

  //   Departure search filter

  const onDepartureChange = (e) => {
    let value = e.target.value;
    const newFilter = data.filter((item) => {
      return item.title.toLowerCase().includes(value.toLowerCase());
    });

    if (value === "") {
      setFilteredSources([]);
      setDeparture("");
    } else {
      setFilteredSources(newFilter);
      setDeparture(value);
    }
  };

  // Arrival Search Filter

  const onArrivalChange = (e) => {
    let value = e.target.value;
    const newFilter = data.filter((item) => {
      return item.title.toLowerCase().includes(value.toLowerCase());
    });

    if (value === "") {
      setFilteredDestination([]);
      setArrival("");
    } else {
      setFilteredDestination(newFilter);
      setArrival(value);
    }
  };

  // Submit Handle

  const handleClick = () => {
    const data = {
      id: 1,
      from: departure,
      to: arrival,
    };
    handleAddForm(data);
    setDeparture("");
    setArrival("");
  };

  console.log("fiter", departure);
  console.log("asd", arrival);

  // Toggle Value
  const toggle = () => {
    let valueOfDeparture = departure;
    let valueOfArrival = arrival;
    setArrival(valueOfDeparture);
    setDeparture(valueOfArrival);
  };

  return (
    <div className="total_form">
      <div className="type_departure">
        <input
          type="text"
          placeholder="Enter Origin"
          value={departure}
          onChange={onDepartureChange}
        />
        <div className="pickup">
          <CgIcons.CgShapeCircle />
        </div>

        {filteredSources.length !== 0 && (
          <div className="departure_dropdown">
            <ul>
              {filteredSources.map((source) => (
                <li
                  onClick={() => {
                    setDeparture(source.title);
                    setFilteredSources([]);
                  }}
                  key={source.id}
                >
                  {source.title}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Toggler */}
      <div className="toggler" onClick={toggle}>
        <HiIcons.HiOutlineSwitchHorizontal />
      </div>
      <div className="type_arrival">
        <input
          type="text"
          placeholder="Enter Destination"
          value={arrival}
          onChange={onArrivalChange}
        />
        <div className="drop">
          <TbIcons.TbMapPin />
        </div>

        {filteredDestination.length !== 0 && (
          <div className="departure_dropdown">
            <ul>
              {filteredDestination.map((source) => (
                <li
                  onClick={() => {
                    setArrival(source.title);
                    setFilteredDestination([]);
                  }}
                  key={source.id}
                >
                  {source.title}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="date">
        <DatePicker
          name="date"
          placeholder="Enter Date"
          selected={date}
          onChange={(date) => setDate(date)}
          dateFormat="dd/MM/yyyy"
          minDate={new Date()}
          isClearable
        />

        <span className="material-symbols-outlined">calendar_month</span>
      </div>

      <div className="submit">
        <Link to={`/available_bus?from=${departure}&to=${arrival}`}>
          <button id="submit" onClick={handleClick}>
            Search Bus
            <span className="srch-btn">
              <FaIcons.FaSearch />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export const data = [
  {
    id: "0",
    title: "Durgapur",
  },

  {
    id: "1",
    title: "Howrah",
  },

  {
    id: "2",
    title: "Mumbai",
  },

  {
    id: "3",
    title: "New Delhi ",
  },

  {
    id: "4",
    title: "Muzaffarpur",
  },
  {
    id: "5",
    title: "Patna",
  },
  {
    id: "6",
    title: "Madhubani",
  },
  {
    id: "7",
    title: "Darbhanga",
  },
  {
    id: "9",
    title: "Jhansi",
  },
  {
    id: "10",
    title: "Indore",
  },
  {
    id: "11",
    title: "Ranchi",
  },
  {
    id: "12",
    title: "Gawalior",
  },

  {
    id: "13",
    title: "Dehradun",
  },
  {
    id: "14",
    title: "Vishakhapatnam",
  },
  {
    id: "15",
    title: "Pune",
  },
  {
    id: "16",
    title: "Nashik",
  },
  {
    id: "17",
    title: "Ahmadabad",
  },
  {
    id: "18",
    title: "Vadodra",
  },
  {
    id: "19",
    title: "Gandhi Nagar",
  },
  {
    id: "20",
    title: "AurangaBad",
  },
  {
    id: "21",
    title: "Motihari",
  },
  {
    id: "22",
    title: "Prayag Raj",
  },
  {
    id: "23",
    title: "Meerut",
  },
  {
    id: "24",
    title: "Muzaffarnagar",
  },
  {
    id: "25",
    title: "Barabanki",
  },
  {
    id: "26",
    title: "Bhatinda",
  },
  {
    id: "27",
    title: "Betiah",
  },
  {
    id: "28",
    title: "Siwan",
  },
  {
    id: "29",
    title: "Chapra",
  },

  {
    id: "30",
    title: "Secundrabad",
  },
  {
    id: "31",
    title: "Malada",
  },
  {
    id: "32",
    title: "North Pragnas",
  },
  {
    id: "33",
    title: "South Pragnas",
  },
  {
    id: "34",
    title: "Guwahati",
  },
  {
    id: "35",
    title: "Tata Nagar",
  },
  {
    id: "36",
    title: "Jamshedpur",
  },
];

import React from "react";
import Dashboard from "./Dashboard";
import Carousel from "./Carousel";
import Info from "./Info";

export default function Home() {
  return (
    <div className="main">
      <div className="container">
        <Dashboard />
        <Carousel />
        <Info />
      </div>
    </div>
  );
}

export const NavLinks = [
  {
    title: "Home",
    path: "/",
  },

  {
    title: "Contact Us",
    path: "/contact-us",
  },

  {
    title: "About",
    path: "/about",
  },
];

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageRender from "./PageRender";
import Header from "./component/Header";
import Home from "./component/Home";
import NotFound from "./component/NotFound";
import "./assests/scss/base.scss";
import Footer from "./component/Footer";
import { Buses } from "./component/avaiable-bus/Buses";

export default function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <Routes>
          <Route path="/:page" element={<PageRender />} />
          <Route path="/:page/:id" element={<PageRender />} />
          <Route path="/" element={<Home />} />
          <Route path="/available_bus" element={<Buses />} />

          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

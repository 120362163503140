import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="notfound-container">
      <div className="notfound-wrapper">
        <h2>404</h2>
        <p className="err">oops, page not found</p>
        <p>We're sorry, but the page you were looking for doesn't exist.</p>
        <div className="btn">
          <span>
            <Link to="/">go home</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useParams } from "react-router-dom";
import NotFound from "./component/NotFound";

const generatePage = function (pageName) {
  const getComponent = function () {
    return require(`./pages/${pageName}`).default;
  };

  try {
    const component = getComponent();
    return React.createElement(component);
  } catch (err) {
    return <NotFound />;
  }
};

export default function PageRender() {
  const { page, id } = useParams();

  let pageName = "";

  if (id) {
    pageName = `${page}/{id}`;
  } else {
    pageName = `${page}`;
  }

  return generatePage(pageName);
}

import React from "react";
import * as FaIcons from "react-icons/fa";
import * as TbIcons from "react-icons/tb";
import * as MdIcons from "react-icons/md";
import * as BiIcons from "react-icons/bi";
import * as AiIcons from "react-icons/ai";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiFillHome />,
    cName: "nav-text",
  },

  {
    title: "Search Buses",
    path: "/search-buses",
    icon: <FaIcons.FaSearch />,
    cName: "nav-text",
  },

  {
    title: "Offers",
    path: "/offers",
    icon: <MdIcons.MdLocalOffer />,
    cName: "nav-text",
  },

  {
    title: "Get Ticket Details",
    path: "/ticket-details",
    icon: <FaIcons.FaTicketAlt />,
    cName: "nav-text",
  },
  {
    title: "Cancel Ticket",
    path: "/cancel-ticket",
    icon: <TbIcons.TbTicketOff />,
    cName: "nav-text",
  },
  {
    title: "Reschedule Ticket",
    path: "/reschedule Ticket",
    icon: <TbIcons.TbRotateClockwise2 />,
    cName: "nav-text",
  },

  {
    title: "About Us",
    path: "/about",
    icon: <BiIcons.BiDetail />,
    cName: "nav-text",
  },
  {
    title: "Help",
    path: "/help",
    icon: <BiIcons.BiHelpCircle />,
    cName: "nav-text",
  },

  {
    title: "",
  },
];

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { NavLinks } from "./NavLinks";
import Brand from "../assests/images/savari-bus.png";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";

export default function Header() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setSidebar(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div>
      <header className="header-main">
        <nav className="nav-bar margin-lr">
          <div className="left-nav">
            <Link to="/">
              <img src={Brand} alt="" />
            </Link>
          </div>

          {/* ------------------------Main Menu------------------------------------ */}

          <div className="right-nav">
            <ul>
              {NavLinks.map(function (item, index) {
                return (
                  <li key={index}>
                    <Link to={item.path}>{item.title}</Link>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* -----------------------------Main Menu END---------------------*/}

          {/* -----------------------------Mobiel Menu-------------------------------- */}

          <div className="mobile-menu">
            <Link to="#" className="menu-bars">
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          </div>
          <nav
            ref={menuRef}
            className={sidebar ? "nav-menu active" : "nav-menu"}
          >
            <ul className="nav-menu-items" onClick={showSidebar}>
              <li className="navbar-toggle">
                <Link to="#" className="menu-bars">
                  <AiIcons.AiOutlineClose />
                </Link>
              </li>

              <li className="user-registration">Login / Signup</li>
              {SidebarData.map(function (item, index) {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>

          {/* ---------------------------Mobile Menu END---------------------- */}
        </nav>
      </header>
    </div>
  );
}

import React from "react";
import fimg from "../assests/images/photo.png";
import fimg1 from "../assests/images/photo_01.png";

export default function ContactUs() {
  return (
    <div className="contact-main">
      <div className="contact-container">
        <div className="contact-info">
          <div className="getintouch">
            <h1>Get in touch</h1>
            <p className="mob">+91-9631823462</p>
            <p className="eml">help@savaribus.com</p>
            <p className="people">
              <span>
                <img src={fimg1} alt="" />
              </span>
              <strong>Marketing Head</strong> - Hemant Kumar [+91-8858637558]
            </p>
            <p className="people">
              <span>
                <img src={fimg} alt="" />
              </span>
              <strong>General Manager</strong> - Faisal Ali [+91-8292271578]
            </p>
            <p className="addrs">
              J3/E7 - Near Krishna Temple, Malviya Nagar, New Delhi - 110025
            </p>
          </div>
        </div>
        <div className="form-wrapper">
          <form className="forms">
            <input
              type="text"
              name="fname"
              id="fname"
              placeholder="Your Name"
            ></input>

            <input
              type="text"
              name="phone"
              id="phone"
              placeholder="Phone"
            ></input>

            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
            ></input>

            <textarea
              className="message"
              cols="40"
              rows="10"
              placeholder="Message"
              name="message"
            ></textarea>

            <input type="submit" value="Send Message"></input>

            <div className="notes">
              <p>
                SavariBus not sell, share or trade customer information. Your
                privacy is very important to us.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { images } from "../assests/images/offer_section_images/Images";

export default function Carousel() {
  const [width, setWidth] = useState(0);
  const carousel = useRef();

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);
  return (
    <motion.div
      ref={carousel}
      whileTap={{ cursor: "grabbing" }}
      className="carousel"
    >
      <motion.div
        drag="x"
        dragConstraints={{ right: 0, left: -width }}
        className="inner-carousel"
      >
        {images.map((image) => {
          return (
            <motion.div className="item">
              <img src={image} alt="" />
            </motion.div>
          );
        })}
      </motion.div>
    </motion.div>
  );
}

export const FooterData = [
  {
    title: "Muzaffarpur to Patna",
    path: "/muzaffarpur-to-patna",
  },

  {
    title: "Sitamadhi to Darbhanga",
    path: "/sitmadhi-to-darbhanga",
  },
  {
    title: "Muzaffarpur-to-Madhubani",
    path: "/muzaffarpur-to-madhubani",
  },
  {
    title: "Patna to Darbhanga",
    path: "/patna-to-darbhanga",
  },
  {
    title: "Madhubani to Darbhanga",
    path: "/madhubani-to-darbhanga",
  },
];

import React from "react";
import { Form } from "./Form";

export default function Dashboard() {
  return (
    <div className="dashboard">
      <div className="dash-pad">
        <p className="top-text">Book Bus Ticket</p>
        <Form />
      </div>
    </div>
  );
}

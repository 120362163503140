import React from "react";
import { Link } from "react-router-dom";
import { FooterData } from "./FooterData";
import * as VscIcons from "react-icons/vsc";

export default function Footer() {
  return (
    <div>
      <div>
        <div className="footer">
          <div className="ft-links">
            <div className="boxes">
              <h3>Top Buses Routes</h3>
              <ul>
                {FooterData.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link to={item.path}>
                        <VscIcons.VscDebugBreakpointData />
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="boxes">
              <h3>Pages</h3>
              <ul>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/help">Help</Link>
                </li>
                <li>
                  <Link to="/sitemap">Sitemap</Link>
                </li>
              </ul>
            </div>
          </div>
          <p>© SavariBus.com All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}
